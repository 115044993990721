import grafana from '../../assets/services/grafana.png';
import { Link } from 'react-router-dom';
export default function GrafanaEdu() {
    return (
        <div>
            <Link to={'/education'} className=' fixed border border-SCS text-SCS  rounded-lg p-4 bottom-1/4 right-16 z-0  w-fit backButton' >❰ Back</Link>

            <div className='flex flex-col p-16 gap-16 w-2/3 mx-auto bg-SCS my-8 rounded-lg'>
                <div className='flex items-center mx-auto gap-4'>
                    <img src={grafana} className='w-32' alt="Grafana Logo" />
                    <h1 className='code'>Grafana SaaS QuickGuide</h1>
                </div>

                <section>
                    <h2>1. Introduction to Grafana</h2>
                    <p>Grafana is an open-source analytics and monitoring platform that allows you to query, visualize, alert on, and understand your data no matter where it’s stored. It’s commonly used for building dashboards that integrate with various data sources.</p>
                    <p><strong>ELI5:</strong> Think of Grafana as a smart dashboard that can pull data from different places (like your servers or cloud services) and show it in pretty graphs and charts.</p>
                </section>

                <section>
                    <h2>2. Getting Started with Grafana</h2>
                    <p>Once you have access to your Grafana instance, you'll begin by logging into the platform via your browser. Here’s how to get started:</p>
                    <ul>
                        <li><strong>Login:</strong> Access the Grafana instance using the URL and credentials provided by your SaaS provider.</li>
                        <li><strong>Create a Dashboard:</strong> Click on the "Create" button and select "Dashboard" to start building your first visualization.</li>
                        <li><strong>Add Panels:</strong> A dashboard is made up of panels. Each panel represents a different visualization (like graphs, tables, etc.).</li>
                        <li><strong>Save and Share:</strong> Once you’ve configured your dashboard, save it and share it with your team.</li>
                    </ul>
                    <p><strong>Recommendation:</strong> Explore the different panel types and experiment with various data sources to understand how they work together.</p>
                </section>

                <section>
                    <h2>3. Data Sources in Grafana</h2>
                    <p>Grafana’s power lies in its ability to connect to multiple data sources. Here’s how to integrate some common ones:</p>
                    <ul>
                        <li><strong>Prometheus:</strong> A popular choice for monitoring metrics. To add Prometheus, go to "Configuration"{' > '}"Data Sources"{' > '}"Add data source" and select Prometheus. Input the Prometheus server URL and save.</li>
                        <li><strong>Elasticsearch:</strong> For logging and search. Add Elasticsearch as a data source by selecting it from the list and configuring the required fields like URL, index patterns, and time field.</li>
                        <li><strong>MySQL/PostgreSQL:</strong> Connect to relational databases by selecting the respective database type, entering the connection details, and testing the connection.</li>
                        <li><strong>InfluxDB:</strong> Ideal for time-series data. Configure it by providing the database name and credentials.</li>
                    </ul>
                    <p><strong>ELI5:</strong> Grafana is like a universal remote. These data sources are like the devices you connect it to, allowing you to control and visualize data from multiple sources in one place.</p>
                </section>

                <section>
                    <h2>4. Creating and Managing Dashboards</h2>
                    <p>Dashboards in Grafana are collections of panels that visualize data. Here’s how to effectively manage them:</p>
                    <ul>
                        <li><strong>Creating Panels:</strong> Choose a panel type (Graph, Table, Stat, etc.) and configure the data source, queries, and visualization options.</li>
                        <li><strong>Variables:</strong> Use variables in your dashboards to make them more dynamic and reusable. Variables allow you to change data sources or queries on the fly.</li>
                        <li><strong>Templating:</strong> Create dashboard templates that can be used across multiple environments by defining templates for queries and data sources.</li>
                        <li><strong>Organizing Dashboards:</strong> Group related dashboards into folders for better organization. Use tags to easily find and filter dashboards.</li>
                    </ul>
                    <p><strong>Recommendation:</strong> Start with simple dashboards and gradually add complexity as you get comfortable. Use templates and variables to avoid redundant work.</p>
                </section>

                <section>
                    <h2>5. Alerting and Notifications</h2>
                    <p>Grafana’s alerting system allows you to set up custom alerts that notify you when specific conditions are met. Here’s how to configure alerts:</p>
                    <ul>
                        <li><strong>Create Alerts:</strong> Within a panel, go to the “Alert” tab and define the conditions that should trigger an alert. For example, an alert if CPU usage exceeds 80% for 5 minutes.</li>
                        <li><strong>Notification Channels:</strong> Configure where alerts should be sent (Email, Slack, PagerDuty, etc.). Go to “Alerting”{' > '}“Notification channels” to set this up.</li>
                        <li><strong>Alerting Rules:</strong> Define alerting rules to automate actions based on specific conditions. These rules can be configured in the alerting settings.</li>
                    </ul>
                    <p><strong>ELI5:</strong> Alerts are like setting an alarm. You tell Grafana what to watch for (like a server getting too hot), and it will ring the alarm (send a notification) if it happens.</p>
                </section>

                <section>
                    <h2>6. Logs and Monitoring</h2>
                    <p>Monitoring logs in Grafana helps you track down issues and understand system behavior over time. Here’s how to work with logs:</p>
                    <ul>
                        <li><strong>Loki:</strong> Grafana Loki is a logging tool designed to integrate with Grafana. Add Loki as a data source to start querying and visualizing logs.</li>
                        <li><strong>Log Panels:</strong> Use the Log Panel in Grafana to view and filter logs directly within your dashboards.</li>
                        <li><strong>Time Range Controls:</strong> Use the time range controls to narrow down the logs you’re viewing. This is helpful for pinpointing issues during specific time frames.</li>
                    </ul>
                    <p><strong>Recommendation:</strong> Combine logs with metrics in a single dashboard to get a complete view of your system's health.</p>
                </section>

                <section>
                    <h2>7. Plugins and Extensions</h2>
                    <p>Grafana supports a wide range of plugins that extend its functionality. Here’s how to use them:</p>
                    <ul>
                        <li><strong>Install Plugins:</strong> Go to “Configuration”{' > '}“Plugins” and browse the available plugins. Click “Install” to add a plugin to your Grafana instance.</li>
                        <li><strong>Popular Plugins:</strong>
                            <ul>
                                <li><strong>Grafana Worldmap:</strong> Visualize data on a map.</li>
                                <li><strong>Pie Chart:</strong> Adds the ability to create pie chart visualizations.</li>
                                <li><strong>Alertmanager:</strong> Integration with Prometheus Alertmanager for more advanced alerting capabilities.</li>
                            </ul>
                        </li>
                        <li><strong>Custom Plugins:</strong> If you have specific needs, you can develop and install custom plugins. Check the Grafana documentation for the plugin development guide.</li>
                    </ul>
                    <p><strong>ELI5:</strong> Plugins are like apps for your phone. They add extra features to Grafana, so it can do more than it does out of the box.</p>
                </section>

                <section>
                    <h2>8. Security Best Practices</h2>
                    <p>Securing your Grafana instance is crucial, especially when dealing with sensitive data. Here’s how to keep your Grafana setup secure:</p>
                    <ul>
                        <li><strong>Authentication:</strong> Ensure that your Grafana instance is protected with strong authentication methods. SaaS providers typically manage this, but it’s good to review your setup.</li>
                        <li><strong>HTTPS:</strong> Always access Grafana over HTTPS to protect data in transit. This is usually configured by your provider.</li>
                        <li><strong>Role-Based Access Control (RBAC):</strong> Use RBAC to limit what users can do within Grafana based on their roles. This helps prevent unauthorized changes to dashboards and settings.</li>
                        <li><strong>API Keys:</strong> Use API keys for automated access to Grafana data, and manage these keys carefully, limiting their scope and lifetime.</li>
                    </ul>
                    <p><strong>Recommendation:</strong> Regularly review your security settings and update your access controls as your team and use cases evolve.</p>
                </section>

                <section>
                    <h2>9. Scaling Grafana</h2>
                    <p>As your data and usage grow, you might need to scale your Grafana instance. Here’s how:</p>
                    <ul>
                        <li><strong>Horizontal Scaling:</strong> Deploy multiple Grafana instances behind a load balancer to handle increased traffic. SaaS providers often manage scaling automatically, but understanding the process can help with planning.</li>
                        <li><strong>Database Optimization:</strong> Ensure that your backend database (like MySQL or PostgreSQL) is optimized for Grafana’s query load. Consider using read replicas if necessary.</li>
                        <li><strong>Distributed Tracing:</strong> Integrate with tracing tools to manage and monitor distributed systems effectively as they scale.</li>
                    </ul>
                    <p><strong>ELI5:</strong> As your project grows, Grafana might need more power (like a bigger engine for a car). Scaling means adding more resources so Grafana can handle more data and more users without slowing down.</p>
                </section>

                <section>
                    <h2>10. Development with VS Code</h2>
                    <p>Using VS Code can enhance your experience when working with Grafana, especially for developing custom plugins or managing configurations. Here are some recommended tools and extensions:</p>
                    <ul>
                        <li><a href="https://marketplace.visualstudio.com/items?itemName=ms-azuretools.vscode-docker" target="_blank" rel="noopener noreferrer">Docker</a> - Useful if you're running Grafana in a containerized environment. This extension integrates Docker into VS Code, making it easier to manage containers.</li>
                        <li><a href="https://marketplace.visualstudio.com/items?itemName=dbaeumer.vscode-eslint" target="_blank" rel="noopener noreferrer">ESLint</a> - Helps maintain clean and consistent JavaScript code when developing custom plugins.</li>
                        <li><a href="https://marketplace.visualstudio.com/items?itemName=esbenp.prettier-vscode" target="_blank" rel="noopener noreferrer">Prettier</a> - Ensures consistent code formatting, crucial for collaborative development.</li>
                        <li><a href="https://marketplace.visualstudio.com/items?itemName=ms-vscode-remote.remote-containers" target="_blank" rel="noopener noreferrer">Remote - Containers</a> - Allows you to develop directly within a container, ensuring consistency between development and production environments.</li>
                        <li><a href="https://marketplace.visualstudio.com/items?itemName=hediet.vscode-drawio" target="_blank" rel="noopener noreferrer">Draw.io Integration</a> - Useful for creating diagrams and visualizing data flows within your Grafana setups.</li>
                    </ul>
                    <p><strong>Recommendation:</strong> Leverage these tools to streamline your development workflow, especially when working on customizations or large-scale deployments.</p>
                </section>

                <footer style={{ marginTop: '20px', borderTop: '1px solid #ccc', paddingTop: '10px' }}>
                    <p>© 2024 Grafana SaaS Guide. All rights reserved.</p>
                </footer>
            </div>
        </div>
    );
}
