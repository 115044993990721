
import { Routes, Route, } from 'react-router-dom';
import Home from './pages/Home';
import Docs from './pages/Docs';
import Dashboard from './pages/Dashboard';
import TOS from './pages/TOS';
import Privacy from './pages/Privacy';
import Education from './pages/Education';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Contact from './pages/Contact';
import EducationTopics from './pages/EducationTopics';
import { useDispatch } from 'react-redux';
import { fetchServices } from './helpers/allServicesReducer';
import { useEffect } from 'react';
import FAQ from './pages/dashPages/FAQ';
import Profile from './pages/dashPages/Profile';
import History from './pages/dashPages/History';
import MyInstances from './pages/dashPages/MyInstances';
import RentNew from './pages/dashPages/RentNew';
import Feedback from './pages/dashPages/UserFeedback';



export default function App() {
  const dispatch = useDispatch<any>();

  useEffect(() => {
    try {
      dispatch(fetchServices());
      console.log('fetching services');
    }
    catch {
      console.log('BackEnd neodpoveda')
    }
  }, []);

  return (
    <div className='bg-PM text-TC'>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/docs" element={<Docs />} />
        <Route path="/dashboard" element={<Dashboard />} >
          <Route path="myinstances" element={<MyInstances />} />
          <Route path="rentnew" element={<RentNew />} />
          <Route path="history" element={<History />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="feedback" element={<Feedback />} />
          <Route path="profile" element={<Profile />} />
        </Route>
        <Route path="/termsofservice" element={<TOS />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/education" element={<Education />} />
        <Route path="/education/:topic" element={<EducationTopics />} />
        <Route path="/login" element={<Login />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

