import { Link } from "react-router-dom";
export default function DashSidePanel() {
    const stylesLI = "flex gap-2 bg-SCS rounded-md px-4 py-2 hover:bg-TR hover:text-SC";
    const styleLINK = "flex items-center justify-start";

    return (
        <div className="flex flex-col bg-SC h-screen w-fit p-4">
            <div>
                <ul className="DashSideUL flex flex-col gap-2">
                    <li className={`${stylesLI}`}>
                        <Link to="myinstances" className={`${styleLINK}`}>
                            <p className="w-4 text-center mr-4">☁</p>
                            <p>My instances</p></Link>
                    </li>
                    <li className={`${stylesLI}`}>
                        <Link to="rentnew" className={`${styleLINK}`}>
                            <p className="w-4 text-center mr-4">➕</p>
                            <p>Rent New</p></Link>
                    </li>

                    <li className={`${stylesLI}`}>
                        <Link to="history" className={`${styleLINK}`}>
                            <p className="w-4 text-center mr-4">⏲</p>
                            <p>History</p></Link>
                    </li>
                    <li className={`${stylesLI}`}>
                        <Link to="faq" className={`${styleLINK}`}>
                            <p className="w-4 text-center mr-4">?</p>
                            <p>FAQ</p></Link>
                    </li>
                    <li className={`${stylesLI}`}>
                        <Link to="feedback" className={`${styleLINK}`}>
                            <p className="w-4 text-center mr-4">✎</p>
                            <p>Feedback</p></Link>
                    </li>
                    <li className={`${stylesLI}`}>
                        <Link to="profile" className={`${styleLINK}`}>
                            <p className="w-4 text-center mr-4">⚇</p>
                            <p>Profile</p></Link>
                    </li>


                </ul>
            </div>

        </div>
    )

}