import ubuntu from '../../assets/services/ubuntu.png';
import {Link} from 'react-router-dom';
export default function UbuntuEdu() {
        
    return (
    <div className='flex'>
        <Link to={'/education'} className=' fixed border border-SCS text-SCS  rounded-lg p-4 bottom-1/4 right-16 z-0  w-fit backButton' >❰ Back</Link>

        <div className='flex flex-col p-16 gap-16 w-2/3 mx-auto bg-SCS my-8 rounded-lg'>
            <div className='flex items-center mx-auto gap-4'>
                <img src={ubuntu} className='w-32' alt="" />
                <h1 className='code'>Ubuntu Docker Instance QuickGuide</h1>
            </div>

            <section>
                <h2>1. Initial Setup</h2>
                <p>After setting up your Docker-based virtual server, it's crucial to ensure your instance is up to date with the latest software. However, note that since you're in a containerized environment, some actions differ from a full server instance.</p>
                <code>
                    apt-get update && apt-get upgrade -y
                </code>
                <p><strong>ELI5:</strong> Think of this as making sure all your tools and software are the newest versions. This command checks for updates and installs them if necessary.</p>
            </section>

            <section>
                <h2>2. Basic Command Line Usage</h2>
                <p>Even in a Docker-based instance, you'll rely heavily on the command line. Here are some commands you'll frequently use:</p>
                <ul>
                    <li><code>ls</code> - Lists files in the current directory.</li>
                    <li><code>cd</code> - Changes the current directory.</li>
                    <li><code>pwd</code> - Displays the current directory path.</li>
                    <li><code>cp</code> - Copies files or directories.</li>
                    <li><code>mv</code> - Moves or renames files or directories.</li>
                    <li><code>rm</code> - Removes files or directories.</li>
                    <li><code>man</code> - Displays the manual page for a command (limited in Docker).</li>
                </ul>
                <p><strong>Note:</strong> Since this is a Docker instance, not all tools may be pre-installed. You may need to install some utilities with <code>apt-get install &lt;package_name&gt;</code>.</p>
            </section>

            <section>
                <h2>3. User Management</h2>
                <p>User management within a Docker container is usually simplified, as most containers are designed to run as a single user or process. However, if needed, here are some commands:</p>
                <ul>
                    <li><code>adduser &lt;username&gt;</code> - Adds a new user (may require root privileges).</li>
                    <li><code>usermod -aG sudo &lt;username&gt;</code> - Grants sudo privileges to a user.</li>
                    <li><code>passwd &lt;username&gt;</code> - Changes the password for a user.</li>
                    <li><code>deluser &lt;username&gt;</code> - Deletes a user.</li>
                </ul>
                <p><strong>ELI5:</strong> These commands help you manage who can do what on your virtual server. However, in Docker, you might not need this level of control, depending on your setup.</p>
            </section>

            <section>
                <h2>4. File Permissions</h2>
                <p>Managing file permissions is still important in Docker, especially if your container interacts with mounted volumes.</p>
                <ul>
                    <li><code>chmod</code> - Changes the permissions of a file or directory.</li>
                    <li><code>chown</code> - Changes the ownership of a file or directory.</li>
                </ul>
                <p>Example: <code>chmod 755 myscript.sh</code> grants read, write, and execute permissions to the owner, and read and execute permissions to others.</p>
                <p><strong>ELI5:</strong> Permissions determine who can read, write, or run your files. In a Docker setup, this is especially important when dealing with files shared between your host and container.</p>
            </section>

            <section>
                <h2>5. Networking and Firewall Configuration</h2>
                <p>In a Docker environment, networking is handled differently. Firewall settings like <code>ufw</code> are typically managed outside the container on the host machine.</p>
                <ul>
                    <li><code>docker run -p &lt;host_port&gt;:&lt;container_port&gt;</code> - Maps ports between host and container.</li>
                    <li><code>sudo ufw allow &lt;port&gt;</code> - If managing firewall on the host.</li>
                </ul>
                <p>Example: <code>docker run -p 8080:80 myapp</code> exposes port 80 in your container to port 8080 on your host.</p>
                <p><strong>ELI5:</strong> Docker makes it easy to connect your container to the internet or your network. The commands above help you control which ports are accessible.</p>
            </section>

            <section>
                <h2>6. Installing Software</h2>
                <p>Installing software inside a Docker container is straightforward but keep in mind that changes made directly inside a container are ephemeral (temporary).</p>
                <ul>
                    <li><code>apt-get install &lt;package_name&gt;</code> - Installs a new package.</li>
                    <li><code>apt-get remove &lt;package_name&gt;</code> - Removes an installed package.</li>
                </ul>
                <p>Example: <code>apt-get install curl</code> installs the curl command-line tool.</p>
                <p><strong>Recommendation:</strong> If you need persistent changes, consider modifying your Dockerfile or using a volume.</p>
            </section>

            <section>
                <h2>7. Monitoring Server Resources</h2>
                <p>Monitoring within a Docker instance is similar to a standard Ubuntu setup, but keep in mind you're monitoring the container's resources, not the host's.</p>
                <ul>
                    <li><code>top</code> - Displays real-time system processes and resource usage (inside the container).</li>
                    <li><code>docker stats</code> - Provides a live stream of container resource usage.</li>
                </ul>
                <p><strong>ELI5:</strong> Use these commands to see how busy your container is and whether it needs more or fewer resources.</p>
            </section>

            <section>
                <h2>8. Backup and Restore</h2>
                <p>Backups in a Docker context typically involve either exporting the entire container or backing up specific volumes.</p>
                <ul>
                    <li><code>docker export &lt;container_name&gt; -o backup.tar</code> - Exports a container to a file.</li>
                    <li><code>tar -czvf backup.tar.gz /path/to/directory</code> - Creates a compressed backup (for volumes).</li>
                </ul>
                <p><strong>Recommendation:</strong> For more advanced setups, consider using Docker volumes and snapshotting them for backups.</p>
            </section>

            <section>
                <h2>9. Security Best Practices</h2>
                <p>Even in Docker, security is paramount. Here are some best practices:</p>
                <ul>
                    <li>Use minimal base images (e.g., <code>alpine</code>).</li>
                    <li>Run containers as a non-root user.</li>
                    <li>Keep your Docker and base images up to date.</li>
                    <li>Limit container capabilities with Docker security options.</li>
                </ul>
                <p><strong>ELI5:</strong> Think of Docker containers as boxes. You want them as secure as possible, with only the necessary tools inside, and the locks tightly closed.</p>
            </section>

            <section>
                <h2>10. Development with VS Code</h2>
                <p>VS Code is a powerful tool for working with Docker. Here are some recommended extensions:</p>
                <ul>
                    <li><a href="https://marketplace.visualstudio.com/items?itemName=ms-azuretools.vscode-docker" target="_blank" rel="noopener noreferrer">Docker</a> - Provides full Docker integration for VS Code.</li>
                    <li><a href="https://marketplace.visualstudio.com/items?itemName=ms-vscode-remote.remote-containers" target="_blank" rel="noopener noreferrer">Remote - Containers</a> - Develop inside a Docker container, with full VS Code integration.</li>
                    <li><a href="https://marketplace.visualstudio.com/items?itemName=esbenp.prettier-vscode" target="_blank" rel="noopener noreferrer">Prettier</a> - Helps format your code consistently.</li>
                    <li><a href="https://marketplace.visualstudio.com/items?itemName=dbaeumer.vscode-eslint" target="_blank" rel="noopener noreferrer">ESLint</a> - Integrates ESLint into VS Code, which helps with JavaScript and TypeScript code quality.</li>
                </ul>
                <p><strong>Recommendation:</strong> Use the <code>Remote - Containers</code> extension to edit your code directly within the container, ensuring your development environment matches production.</p>
            </section>

            <footer style={{ marginTop: '20px', borderTop: '1px solid #ccc', paddingTop: '10px' }}>
                <p>© 2024 Ubuntu Docker Guide. All rights reserved.</p>
            </footer>
        </div>
        </div>
        );
}
