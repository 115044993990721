import { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import UbuntuEdu from './topics/ubuntuEdu';
import NoderedEdu from './topics/noderedEdu';
import GrafanaEdu from './topics/grafanaEdu';
import PostgreEdu from './topics/postgreEdu';
import MongoDBEdu from './topics/mongodbEdu';
import WordPressEdu from './topics/wordpressEdu';
export default function EducationTopics() {
    let { topic } = useParams();

    const renderContent = () => {
        switch (topic) {
            case 'ubuntu':
                return <UbuntuEdu />;
            case 'nodered':
                return <NoderedEdu />;
            case 'grafana':
                return <GrafanaEdu />;
            case 'postgre':
                return <PostgreEdu />;
            case 'mongodb':
                return <MongoDBEdu />;
            case 'wordpress':
                return <WordPressEdu />;

            default:
                return <h2>Topic not found</h2>;
        }
    };

    return (
        <div>
            <Suspense fallback={<div>Loading...</div>}>
                {renderContent()}
            </Suspense>
        </div>
    );
}
