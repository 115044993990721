export default function Contact() {
    return (
        <div className="bg-TR">
            <div>
                <div>
                    <p>ASD</p>
                    <h1>ASD</h1>
                    <h4>ASD</h4>
                </div>
            </div>
        </div>
    )
}
