import { Link } from 'react-router-dom';
import logo from '../assets/logo1small.png';
import Button from '../UI/Button';
import { useEffect, useState } from 'react';
import google from '../assets/login/google.svg';
import github from '../assets/login/github.svg';
import { fetchUser } from '../helpers/allServicesReducer';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
type UserData = {
    userData: string
}
export default function Header() {
    const [loginModal, setLoginModal] = useState(false);
    const dispatch = useDispatch<any>()
    const isLoggedName = useSelector((state:any)=> state.services.user.displayName || 'none' as string)

    window.addEventListener('scroll', () => {
        const header = document.querySelector('header');
        header?.classList.toggle('sticky', window.scrollY > 0);
    });

    const toggleLoginModal = () => {
        setLoginModal(prev => !prev)
    }

    useEffect(() => {
        const header = document.querySelector('header');
        if (loginModal) {
            header?.classList.add('sticky');
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [loginModal])

    const popUpWindow = (url: string, width = 500, height = 500) => {
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;
        return window.open(
            url,
            '_blank',
            `toolbar=no, location=no, status=no, menubar=no, scrollbars=no, resizable=no, width=${width}, height=${height}, top=${top}, left=${left}`
        );
    }
    const googleLogin = () => {
        const googleLoginWindow = popUpWindow('https://iclabs.eu/backend/auth/google');
        setLoginModal(false);

        const messageListener = (event: any) => {
            if (event.data.event === 'oauth_success') {
                if (googleLoginWindow) {
                    googleLoginWindow.close();
                }
                dispatch(fetchUser()); 
                window.removeEventListener('message', messageListener);
            } else {
                console.warn('Unexpected message received', event); 
            }
        };
        window.addEventListener('message', messageListener);


    };

    const githubLogin = () => {
        const githubLoginWindow = popUpWindow('https://iclabs.eu/backend/auth/github');
        setLoginModal(false);

    }
    return (<>
        <div className='shadow-none flex justify-center items-center h-fit w-full md:hidden relative headerZSet '>
            <div className='flex gap-4 items-center pt-8'>
                <img src={logo} className='w-6' alt='Logo' />
                <h2 className='code text-2xl  text-TC'>ICLabs</h2>
            </div>
            <button className='fixed right-1 top-1 w-16 h-14 bg-SC rounded-lg font-black text-2xl text-TR p-2'>☰</button>

        </div>
        <header className='hidden md:flex 
        bg-SC text-TC backdrop-blur-sm h-fit justify-between px-8 p-4 items-center headerZSet ' >
            <div>{/* Logo + Nazov Div */}
                <Link to={'/'} className='flex items-center sm:gap-2 md:gap-4 lg:gap-8'>
                    <img src={logo} className='sm:w-4 md:w-6 lg:w-8' alt='Logo' />
                    <h2 className='code '>ICLabs</h2>
                </Link>
            </div>

            <div >{/* Navigacia */}
                <ul className='flex 
                md:gap-4 lg:gap-16 
                sm:text-sm md:text-md lg:text-lg 
                font-bold '>
                    <li><Link to={'/docs'} className='hover:text-ACS p-2' >Docs</Link></li>
                    <li><Link to={'/dashboard'} className='hover:text-ACS p-2' >Dashboard</Link></li>
                    <li><Link to={'/education'} className='hover:text-ACS p-2' > Education</Link></li>
                    <li className='relative group'>
                        <span className='cursor-pointer group-hover:text-ACS '>Legals</span>
                        <ul className='absolute hidden group-hover:flex flex-col gap-4 w-fit truncate p-4 rounded-md -translate-x-1/3 bg-SCS border border-TR'>
                            <li><Link to={'/termsofservice'} className='hover:bg-PM p-2 rounded-md w-full flex'>📜 Terms of Service</Link></li>
                            <li><Link to={'/privacy'} className='hover:bg-PM p-2 rounded-md w-full flex'>🛡️ Privacy</Link></li>
                        </ul>

                    </li>
                    <li><Link to={'/contact'} className='hover:text-ACS p-2' >Contact</Link></li>

                </ul>

            </div>

            {isLoggedName==='none'?(<div onClick={() => toggleLoginModal()}>{/* Login/Welcome */}
                <Button type={'primary'}>Get Started</Button>
            </div>):
            <p>Welcome{' '}{isLoggedName}</p>
            }
            {/* Login Modal */}
            <div onClick={() => toggleLoginModal()} className={`${loginModal === true ? 'flex' : 'hidden'} loginModal items-center justify-center fixed left-0 top-0 w-full h-screen  `}>

                <div onClick={(e) => e.stopPropagation()} className='bg-CS w-fit h-fit border border-ACS bg-SCS rounded-2xl  z-50 flex flex-col items-center gap-8 px-12 py-8 justify-center relative shadow-md shadow-ACS'>
                    <div className='absolute top-0 right-0'>
                        <button className=' px-4 py-2' onClick={() => toggleLoginModal()}>
                            X
                        </button>
                    </div>
                    <div className='flex flex-col gap-8 '>

                        <div className='flex items-center justify-center gap-4 pb-4 '>
                            <img src={logo} alt="logo" className='w-8' />
                            <h2 className='code'>ICLabs</h2>

                        </div>
                        <div className='flex flex-col items-center gap-2'>
                            <h4 className='code'>Please log into your account</h4>
                            <p className=''>Welcome! Choose from login options</p>
                        </div>
                        <div className='flex gap-4 items-center justify-center cursor-pointer' >
                            <label className='flex gap-2 items-center cursor-pointer'>
                                <input type="checkbox" name="" id="" className='custom-checkbox appearance-none bg-SC  checked:bg-ACS rounded-sm checked:text-SC' />
                                <p>Keep me signed</p>
                            </label>
                        </div>



                    </div>
                    <div className='flex gap-4 items-center'>
                        <button onClick={() => googleLogin()} className='flex gap-4 items-center px-6 py-2 bg-SC rounded-lg hover:border-ACS border border-transparent'>
                            <img src={google} alt="Google" className='w-4' />
                            <p>Google</p>
                        </button>
                        <button onClick={() => githubLogin()} className='flex gap-4 items-center px-6 py-2 bg-SC rounded-lg  hover:border-AC border border-transparent'>
                            <img src={github} alt="Github" className='w-4' />
                            <p>Github</p>
                        </button>

                    </div>

                </div>

            </div>
        </header >
    </>);
}

