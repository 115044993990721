import Roman from '../assets/team/roman.png';
import Samo from '../assets/team/samo.png';
export default function Team() {
    return (
        <div className='bg-TR'>
            <div className='flex flex-col'>

                <div className='flex flex-col mx-auto pt-16 pb-8'>
                    <p className="text-AC pl-2">brick by brick</p>

                    <h1 className='code text-SC'>Our Team Loves to Build</h1>
                </div>
                <div className='flex px-4 md:px-0 md:w-1/2 mx-auto'>
                    <h4 className='text-SCS'>We are a team of passionate developers, designers, and engineers who love to build. We are dedicated to creating innovative solutions that empower businesses to succeed. Our team is committed to delivering high-quality products and services that exceed our clients' expectations. Contact us today to learn more about how we can help you achieve your goals.
                    </h4>
                </div>
            </div>
            <div className='flex flex-col md:flex-row w-full justify-center gap-16 md:gap-32 pb-8 pt-16'>
                <div className='flex flex-col gap-2 text-center items-center'>
                    <img src={Roman} className='w-64 h-64 rounded-full object-cover mb-8' alt="" />
                    <h4 className='code text-SC font-bold text-2xl'>Roman Janek</h4>
                    <p className='text-SCS'>Full-Stack Developer & UI/UX Designer</p>


                </div>
                <div className='flex flex-col gap-2 text-center items-center'>
                    <img src={Samo} className='w-64 rounded-full mb-8' alt="" />
                    <h4 className='code text-SC font-bold text-2xl'>ing. Samuel Komolík</h4>
                    <p className='text-SCS'>DevOps Engineer & Infrastructure Specialist</p>
                </div>
            </div>
            <div className='w-full text-center py-16 '>
                <h4 className='text-SC font-bold '>iclabs.sdev@skap.dorana</h4>
            </div>

        </div>
    )
}