import MongoDB from '../assets/services/mongodb.png';
import Grafana from '../assets/services/grafana.png';
import NodeRed from '../assets/services/nodered.png';
import PostGre from '../assets/services/postgre.png';
import WooCommerce from '../assets/services/woocommerce.png';
import WordPress from '../assets/services/wordpress.png';
import Ubuntu from '../assets/services/ubuntu.png';
import Button from '../UI/Button';
import { Outlet } from 'react-router-dom';

export default function Education() {
    return (
        <div>
            <div>
                <div className='flex flex-col'>

                    <div className='flex flex-col text-center py-16 w-1/2 mx-auto'>
                        <h1 className='code'>🕮 Essential knowledge for running your instances right</h1>
                        <h4 className='text-TC'>We are here to help you navigate the cloud. Our team of experts is ready to assist you with any questions or concerns you may have. We are committed to providing you with the best possible service and support. Contact us today to learn more about our cloud services and how we can help you achieve your goals.</h4>
                    </div>
                </div>
            </div>
            <div className=" grid grid-cols-5  gap-16 p-16">
                <div className="mx-auto row-span-1 bg-SCS p-4 rounded-lg w-80 gap-8 py-8 flex flex-col">
                    <div className='flex items-center px-4 justify-start gap-4'>
                        <img className='w-16' src={Grafana} alt="Grafana Logo" />
                        <h4>Grafana</h4>
                    </div>
                    <p className='px-4'>Learn how to set up Grafana, create dashboards, and monitor your data in real-time.</p>
                    <div className='w-fit px-4'>
                        <Button leadTo={'/education/grafana'} type={'secondary'}>Learn More</Button>
                    </div>
                </div>

                <div className="mx-auto row-span-1 bg-SCS p-4 rounded-lg w-80 gap-8 py-8 flex flex-col">
                    <div className='flex items-center px-4 justify-start gap-4'>
                        <img className='w-16' src={MongoDB} alt="MongoDB Logo" />
                        <h4>MongoDB</h4>
                    </div>
                    <p className='px-4'>Understand how to set up MongoDB, manage your databases, and perform basic CRUD operations.</p>
                    <div className='w-fit px-4'>
                        <Button leadTo={'/education/mongodb'} type={'secondary'}>Learn More</Button>
                    </div>
                </div>

                <div className="mx-auto row-span-1 bg-SCS p-4 rounded-lg w-80 gap-8 py-8 flex flex-col">
                    <div className='flex items-center px-4 justify-start gap-4'>
                        <img className='w-16' src={NodeRed} alt="Node-RED Logo" />
                        <h4>Node-RED</h4>
                    </div>
                    <p className='px-4'>Learn how to create automated workflows using Node-RED's intuitive drag-and-drop interface.</p>
                    <div className='w-fit px-4'>
                        <Button leadTo={'/education/nodered'} type={'secondary'}>Learn More</Button>
                    </div>
                </div>

                <div className="mx-auto row-span-1 bg-SCS p-4 rounded-lg w-80 gap-8 py-8 flex flex-col">
                    <div className='flex items-center px-4 justify-start gap-4'>
                        <img className='w-16' src={PostGre} alt="PostgreSQL Logo" />
                        <h4>PostgreSQL</h4>
                    </div>
                    <p className='px-4'>Get started with PostgreSQL by learning how to set it up, manage databases, and execute SQL queries.</p>
                    <div className='w-fit px-4'>
                        <Button leadTo={'/education/postgre'} type={'secondary'}>Learn More</Button>
                    </div>
                </div>

                <div className="mx-auto row-span-1 bg-SCS p-4 rounded-lg w-80 gap-8 py-8 flex flex-col">
                    <div className='flex items-center px-4 justify-start gap-4'>
                        <img className='w-16' src={Ubuntu} alt="Ubuntu Logo" />
                        <h4>Ubuntu Server</h4>
                    </div>
                    <p className='px-4'>Understand the basics of managing an Ubuntu server, including package management, user management, and system monitoring.</p>
                    <div className='w-fit px-4'>
                        <Button leadTo={'/education/ubuntu'} type={'secondary'}>Learn More</Button>
                    </div>
                </div>

                <div className="mx-auto row-span-1 bg-SCS p-4 rounded-lg w-80 gap-8 py-8 flex flex-col">
                    <div className='flex items-center px-4 justify-start gap-4'>
                        <img className='w-16' src={WordPress} alt="WordPress Logo" />
                        <h4>WordPress</h4>
                    </div>
                    <p className='px-4'>Learn how to install WordPress, set up your first site, and customize it with themes and plugins.</p>
                    <div className='w-fit px-4'>
                        <Button leadTo={'/education/wordpress'} type={'secondary'}>Learn More</Button>
                    </div>
                </div>



            </div>
            <Outlet />
        </div>
    );
}