import MongoDB from '../assets/services/mongodb.png';
import Grafana from '../assets/services/grafana.png';
import NodeRED from '../assets/services/nodered.png';
import PostGre from '../assets/services/postgre.png';
import WooCommerce from '../assets/services/woocommerce.png';
import WordPress from '../assets/services/wordpress.png';
import Ubuntu from '../assets/services/ubuntu.png';
import { useDispatch } from "react-redux";
type Service = {
    id: number;
    saas: string;
    name: string;
    vcpu: number;
    ram: number;
    storage: number;
    price: string;
    currency: string;
    active: boolean;
    numberofinstances: number;
};
/*
            {
        id:1
        saas:"Node-RED"
        name:"Node-RED ESSENTIAL"
        vcpu:1
        ram:1
        storage:5
        price:"5.00"
        currency:"EUR"
        active:true
        numberofinstances:1
        }
         */


export default function GuideTextSection( {selectorAllServices}: {selectorAllServices: Service[]}) {
    const dispatch = useDispatch();
    const originalServicesArray = Array.from(new Set(selectorAllServices.map((service) => service.saas)));


    const imageMap: { [key: string]: string } = {
        MongoDB,
        Grafana,
        NodeRED,
        PostGre,
        WooCommerce,
        WordPress,
        Ubuntu
    };
    const formatServiceName = (name: string): string => {
        return name.replace(/[^a-zA-Z0-9]/g, '') ;  
    };
    const choosenService = (service:string):Service[] => {
       const choosenServices = selectorAllServices.filter((item) => item.saas === service);
         return choosenServices;
    }

    return (
        <div className="flex flex-col text-start w-full md:px-0 md:w-1/3 mb-24 md:mb-0">
            {/*Text a bublinka s appkami*/}
            <p className="text-ACS pl-6 md:pl-2">so easy to use</p>
            <h1 className="code w-full px-4 md:px-0">Configure, Create & Manage</h1>
            <div className="flex md:flex-wrap gap-2 my-8 bg-SCS p-8 md:rounded-lg md:shadow-md shadow-SCS overflow-x-scroll md:overflow-hidden">

                {originalServicesArray.map((service,key) => {
                    return (
                        <div className="flex  bg-SC hover:bg-PM rounded-lg py-2 px-4" key={key}>

                            <button
                                onClick={() => dispatch({ type: 'services/changeSelectedServiceLanding', payload: choosenService(service) })}
                                className="text-ACS flex flex-col md:flex-row gap-2 items-center"
                            >
                                <img src={imageMap[formatServiceName(service)]} alt="" className="h-16 md:h-8 w-fit" />{service}
                            </button>
                        </div>
                    );
                })}

            </div>
                        {/*Texty s vyplnou*/}

            <div className="flex flex-col p-2 px-4 md:px-0 mt-4 gap-8 ">
                <div className="flex gap-4 items-center">
                    <h2 className="text-ACS w-8">☁</h2>
                    <h4 className="inline-flex gap-1 flex-wrap">
                        Create your solution on{" "}
                        <span className=" text-ACS inline-block">1 click</span> as you are used to
                    </h4>
                </div>
                <div className="flex gap-4 items-center">
                    <h2 className="text-ACS w-8">☍</h2>
                    <h4 className="inline-flex gap-1 flex-wrap">
                        Manage necessary things{" "}
                        <span className=" text-ACS inline-block">as you are used to</span>
                    </h4>
                </div>
                <div className="flex gap-4 items-center">
                    <h2 className="text-ACS w-8">$</h2>
                    <h4 className="inline-flex gap-1 flex-wrap">
                        Pay way{" "}
                        <span className=" text-ACS inline-block">less</span> than you are used to for the same service
                    </h4>
                </div>
            </div>
        </div>
    );
}
