import MongoDB from '../assets/services/mongodb.png';
import Grafana from '../assets/services/grafana.png';
import NodeRED from '../assets/services/nodered.png';
import PostGre from '../assets/services/postgre.png';
import WooCommerce from '../assets/services/woocommerce.png';
import WordPress from '../assets/services/wordpress.png';
import Ubuntu from '../assets/services/ubuntu.png';
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';

type Service = {
    id: number;
    saas: string;
    name: string;
    vcpu: number;
    ram: number;
    storage: number;
    price: string;
    currency: string;
    active: boolean;
    numberofinstances: number;
};

export default function GuideVisualSection( {selectorAllServices}: {selectorAllServices: Service[]}) {
    const selector = useSelector((state: any) => state.services.selectedServiceLanding);
    const [selectedService, setSelectedService] = useState<Service[]>(selectorAllServices);
    const [tier, setTier] = useState<number>(3);
    useEffect(() => {
        setSelectedService(selector);
    }, [selector]);

    const imageMap: { [key: string]: string } = {
        MongoDB,
        Grafana,
        NodeRED,
        PostGre,
        WooCommerce,
        WordPress,
        Ubuntu
    };
    const formatServiceName = (name: string): string => {
        return name.replace(/[^a-zA-Z0-9]/g, '') ;  
    };
    if (tier > selectedService.length) {
        console.error('Tier index out of bounds');
        console.error('service length:', selectedService.length);
        console.log('selectedService:', selectedService);
        console.error('Tier:', tier);
        return null;
    }

    const service = selectedService[tier];
    console.log(imageMap[formatServiceName(service.saas)]) // undefined
    console.log(formatServiceName(service.saas)) // 'NodeRED'
    return (
        <div className="flex w-full md:w-1/3 justify-center md:guidePlateContainer ">
            <div className="w-full md:w-4/5 h-full bg-PM md:rounded-lg md:guidePlate flex flex-col">
                                {/*Navi*/}
                <ul className='flex w-full h-8 '> 
                    <li className='w-1/4 h-full'><button className={`${tier === 0 ? 'bg-PM rounded-tl-lg' : 'bg-SCS border-b-2 border-r-2 border-PM md:rounded-tl-lg'} w-full h-full ${tier === 0 ? '' : 'hover:bg-SCS'}`} onClick={() => setTier(0)}>tiny</button></li>
                    <li className='w-1/4'><button className={`${tier === 1 ? 'bg-PM' : 'bg-SCS border-b-2 border-r-2 border-PM'} w-full h-full ${tier === 1 ? '' : 'hover:bg-SCS'}`} onClick={() => setTier(1)}>basic</button></li>
                    <li className='w-1/4'><button className={`${tier === 2 ? 'bg-PM' : 'bg-SCS border-b-2 border-r-2 border-PM'} w-full h-full ${tier === 2 ? '' : 'hover:bg-SCS'}`} onClick={() => setTier(2)}>intermediate</button></li>
                    <li className='w-1/4'><button className={`${tier === 3 ? 'bg-PM rounded-tr-lg' : 'bg-SCS md:rounded-tr-lg border-b-2  border-PM'}  w-full h-full ${tier === 3 ? '' : 'hover:bg-SCS'}`} onClick={() => setTier(3)}>metal</button></li>
                </ul>
                <div className="flex justify-center pt-8 items-center h-fit gap-4">
                    <img src={imageMap[formatServiceName(service.saas)]} alt="AppIcon" className='w-16' />
                    <h1 className="text-ACS code">My Sick app</h1>
                </div>
                {/*data contajnre*/}
                <div className="flex flex-col justify-center items-center h-2/3 gap-4 px-2 md:px-0">
                    <div className="w-full md:w-4/5 h-fit bg-SC rounded-lg flex justify-between p-4 code">
                        <div>
                            <p>OS: Linux</p>
                            <p>Server: Nginx</p>
                            <p className="sliding-text">App: {service.name}</p>
                        </div>
                        <div>
                            <p>URL: xyz.com</p>
                            <p>IP: 192.156.32.17</p>
                            <p>Port: 80</p>


                        </div>
                    </div>

                    <div className="w-full md:w-4/5 h-fit bg-SC rounded-lg flex justify-between gap-2 p-4">
                        <div className='flex flex-col'>
                            <p className="sliding-text">CPU: {service.vcpu}vCPU</p>
                            <p className="sliding-text">RAM: {service.ram}Gb</p>
                            <p className="sliding-text">Storage: {service.storage}Gb</p>
                        </div>
                        <div>
                            <p>Status: OK</p>
                            <p>Uptime: 99%</p>
                            <p>Users: 1</p>
                        </div>

                    </div>
                    <div className="w-full md:w-4/5 h-fit bg-SC rounded-lg flex justify-between gap-2 p-4 mb-4 md:mb-0">
                        <div>
                            <p>Start: 2021-10-12</p>
                            <p className="sliding-text">Auto-Renew: {service.price} {service.currency === 'EUR'? '€':'$'}/month</p>
                        </div>
                        <div>
                            <p>Location: EU</p>
                            <p>Region: DE</p>
                            <p>Zone: 1</p>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}