import {Link} from 'react-router-dom';

type ButtonProps = {
    type: 'primary' | 'secondary' | 'tertiary';
    leadTo?: string | undefined;
    children: string;
}

export default function Button(props:ButtonProps){ 
    const getStyles = (type: string) => {
        const basicStyles = 'flex px-4 py-3 rounded-md text-md font-medium ';
        switch (type) {
            case 'primary':
                return 'bg-PM text-TC hover:bg-ACS hover:text-SC animate-primary ' + basicStyles;
            case 'secondary':
                return 'bg-SCS text-TC hover:bg-SC animate-primary border border-SC ' + basicStyles;
            case 'tertiary':
                return 'bg-transparent hover:bg-SC animate-primary border-2 border-SCS text-TC '  + basicStyles;
        }
    }
    return (
        props.leadTo ? 
        <Link to={props.leadTo} className={`${getStyles(props.type)}`}>{props.children}</Link> :
        <button className={`${getStyles(props.type)} `}>{props.children}</button>
    );
}