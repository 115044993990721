import Button from "../UI/Button";
import cloudy1 from '../assets/Cloudy1.svg';
import cloudy2 from '../assets/Cloudy2.svg';
import cloudy3 from '../assets/Cloudy3.svg';

type HeroTexts = {
    titleText: string;
    subTitle: string;
    salesText: string;
}

export default function Hero(texts: HeroTexts) {
    
    return (
        <div className="relative md:h-screen flex flex-col items-center overflow-hidden z-0 order-1">
            {/* Cloud Backgrounds */}
            <div className="hidden md:block absolute top-0 left-0 w-full h-full pointer-events-none">
                <div className="absolute cloud1 w-full h-full"
                    style={{
                        backgroundImage: `url(${cloudy1})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'bottom left',
                        backgroundRepeat: 'no-repeat'
                    }}>
                </div>
                <div className="absolute cloud2 w-full h-full"
                    style={{
                        backgroundImage: `url(${cloudy2})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'bottom center',
                        backgroundRepeat: 'no-repeat'
                    }}>
                </div>
                <div className="absolute cloud3 w-full h-full"
                    style={{
                        backgroundImage: `url(${cloudy3})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'bottom right',
                        backgroundRepeat: 'no-repeat'
                    }}>
                </div>
            </div>


            {/* Hero Texts and Buttons */}
            <div className="  flex flex-col gap-8 text-center pt-16 pb-8 px-4 md:p-32 ">
                <div className="HeroTexts 
                mb-8 md:mb-1
                ">
                    <h1 className="font-bold gradText drop-shadow-lg code mb-8 md:mb-4">{texts.titleText}</h1>
                    <h4 className="text-lg md:text-xl">{texts.subTitle}</h4>
                </div>
                <div className="flex flex-col gap-4 items-center mx-auto">
                    <div className="flex gap-4">
                        <Button type="secondary" leadTo="/dashboard">➕ Create</Button>
                        <Button type="tertiary" leadTo="/dashboard">🗎 Dev Zone</Button>
                    </div>
                    <p className="text-base md:text-lg">{texts.salesText}</p>
                </div>
            </div>
        </div>
    );
}
