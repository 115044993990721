import postgresql from '../../assets/services/postgre.png';
import { Link } from 'react-router-dom';

export default function PostgreEdu() {
    return (
        <div>
            <Link to={'/education'} className='fixed border border-SCS text-SCS rounded-lg p-4 bottom-1/4 right-16 z-0 w-fit backButton'>❰ Back</Link>

            <div className='flex flex-col p-16 gap-16 w-2/3 mx-auto bg-SCS my-8 rounded-lg'>
                <div className='flex items-center mx-auto gap-4'>
                    <img src={postgresql} className='w-32' alt="PostgreSQL Logo" />
                    <h1 className='code'>PostgreSQL SaaS QuickGuide</h1>
                </div>

                <section>
                    <h2>1. Introduction to PostgreSQL</h2>
                    <p>PostgreSQL is a powerful, open-source relational database management system (RDBMS) known for its robustness, flexibility, and SQL compliance. It's a popular choice for web applications, data analytics, and more.</p>
                    <p><strong>ELI5:</strong> Think of PostgreSQL as a highly organized digital filing cabinet that can store, sort, and retrieve vast amounts of data efficiently.</p>
                </section>

                <section>
                    <h2>2. Getting Started with PostgreSQL</h2>
                    <p>After signing up for a PostgreSQL SaaS account, you’ll start by setting up your database. Here’s how to get started:</p>
                    <ul>
                        <li><strong>Create a Database:</strong> In the PostgreSQL dashboard, create a new database to store your data.</li>
                        <li><strong>Connect to the Database:</strong> Use the connection string provided by your SaaS provider to connect to the database. You can connect using psql (PostgreSQL's command-line tool), a GUI like pgAdmin, or directly from your application.</li>
                        <li><strong>Create Tables:</strong> Define the structure of your data by creating tables with specific columns and data types.</li>
                    </ul>
                    <p><strong>Recommendation:</strong> Start by exploring pgAdmin or any other PostgreSQL client to familiarize yourself with the database structure and available tools.</p>
                </section>

                <section>
                    <h2>3. Basic PostgreSQL Queries</h2>
                    <p>PostgreSQL uses SQL (Structured Query Language) for querying and managing data. Here are some common queries:</p>
                    <ul>
                        <li><strong>Select Data:</strong> Retrieve data from a table using the <code>SELECT</code> statement.
                            <pre>{`SELECT * FROM users WHERE age >= 18;`}</pre>
                            This query retrieves all users who are 18 years or older.
                        </li>
                        <li><strong>Insert Data:</strong> Add new records to a table using the <code>INSERT INTO</code> statement.
                            <pre>{`INSERT INTO users (name, age, email) VALUES ('John Doe', 30, 'john@example.com');`}</pre>
                            This query inserts a new user into the users table.
                        </li>
                        <li><strong>Update Data:</strong> Modify existing records with the <code>UPDATE</code> statement.
                            <pre>{`UPDATE users SET age = 31 WHERE name = 'John Doe';`}</pre>
                            This query updates the age of John Doe to 31.
                        </li>
                        <li><strong>Delete Data:</strong> Remove records from a table using the <code>DELETE FROM</code> statement.
                            <pre>{`DELETE FROM users WHERE name = 'John Doe';`}</pre>
                            This query deletes the user named John Doe from the users table.
                        </li>
                    </ul>
                    <p><strong>ELI5:</strong> Queries are like instructions you give to the database, telling it to find, add, update, or delete certain information.</p>
                </section>

                <section>
                    <h2>4. Advanced Query Techniques</h2>
                    <p>As you grow more comfortable with PostgreSQL, you can start using more advanced query techniques:</p>
                    <ul>
                        <li><strong>Joins:</strong> Combine data from multiple tables using JOINs.
                            <pre>{`SELECT orders.id, users.name, orders.total 
FROM orders 
JOIN users ON orders.user_id = users.id;`}</pre>
                            This query retrieves order details along with the corresponding user names.
                        </li>
                        <li><strong>Subqueries:</strong> Use subqueries to nest queries within queries.
                            <pre>{`SELECT name 
FROM users 
WHERE id = (SELECT user_id FROM orders WHERE id = 123);`}</pre>
                            This query retrieves the name of the user who made a specific order.
                        </li>
                        <li><strong>Aggregations:</strong> Perform calculations on data using aggregate functions like COUNT, SUM, AVG, etc.
                            <pre>{`SELECT COUNT(*) AS total_users FROM users WHERE age >= 18;`}</pre>
                            This query counts how many users are 18 years or older.
                        </li>
                        <li><strong>Window Functions:</strong> Use window functions for more complex calculations across query results.
                            <pre>{`SELECT name, age, 
RANK() OVER (ORDER BY age DESC) AS age_rank 
FROM users;`}</pre>
                            This query ranks users by age in descending order.
                        </li>
                    </ul>
                    <p><strong>Recommendation:</strong> Use these advanced queries to extract and analyze data more effectively, especially when working with complex datasets.</p>
                </section>

                <section>
                    <h2>5. Indexing and Performance</h2>
                    <p>Indexing is crucial for optimizing query performance in PostgreSQL. Here’s how to manage indexes:</p>
                    <ul>
                        <li><strong>Create an Index:</strong> Use the <code>CREATE INDEX</code> statement to improve query performance.
                            <pre>{`CREATE INDEX idx_users_email ON users(email);`}</pre>
                            This creates an index on the email column in the users table, making searches by email faster.
                        </li>
                        <li><strong>Unique Indexes:</strong> Ensure data uniqueness with unique indexes.
                            <pre>{`CREATE UNIQUE INDEX idx_users_username ON users(username);`}</pre>
                            This ensures that no two users can have the same username.
                        </li>
                        <li><strong>Partial Indexes:</strong> Create indexes on a subset of data.
                            <pre>{`CREATE INDEX idx_active_users ON users(status) WHERE status = 'active';`}</pre>
                            This index is only applied to users with an active status.
                        </li>
                        <li><strong>Composite Indexes:</strong> Combine multiple columns in a single index for more efficient querying.
                            <pre>{`CREATE INDEX idx_orders_userid_date ON orders(user_id, order_date);`}</pre>
                            This index helps speed up queries filtering by both user ID and order date.
                        </li>
                    </ul>
                    <p><strong>ELI5:</strong> Indexes are like bookmarks in a large book, allowing the database to quickly find the information it needs without having to flip through every page.</p>
                </section>

                <section>
                    <h2>6. Security Best Practices</h2>
                    <p>Securing your PostgreSQL instance is essential to protect your data. Here are some best practices:</p>
                    <ul>
                        <li><strong>Use Strong Passwords:</strong> Ensure that all user accounts have strong, unique passwords.</li>
                        <li><strong>Enable SSL:</strong> Encrypt data in transit by enabling SSL for connections to your PostgreSQL server.</li>
                        <li><strong>Role-Based Access Control (RBAC):</strong> Implement RBAC to control which users can access specific databases and operations.</li>
                        <li><strong>Data Encryption:</strong> Encrypt sensitive data at rest using PostgreSQL’s encryption features or external tools.</li>
                        <li><strong>Regular Backups:</strong> Schedule regular backups to prevent data loss. Use tools like pg_dump for backups and pg_restore for restoration.</li>
                        <li><strong>Audit Logging:</strong> Enable audit logging to track who is accessing and modifying data in your database.</li>
                    </ul>
                    <p><strong>Recommendation:</strong> Regularly review your security settings and update them to protect against evolving threats.</p>
                </section>

                <section>
                    <h2>7. Scaling PostgreSQL</h2>
                    <p>As your data grows, you may need to scale your PostgreSQL deployment:</p>
                    <ul>
                        <li><strong>Vertical Scaling:</strong> Increase the resources (CPU, RAM) of your PostgreSQL server to handle more data and traffic.</li>
                        <li><strong>Horizontal Scaling:</strong> Implement replication and sharding to distribute data across multiple servers, allowing PostgreSQL to scale out horizontally.</li>
                        <li><strong>Replication:</strong> Set up replication to ensure high availability and data redundancy. Use synchronous or asynchronous replication based on your needs.</li>
                        <li><strong>Load Balancing:</strong> Use load balancers to distribute queries across multiple read replicas, reducing the load on your primary database.</li>
                    </ul>
                    <p><strong>ELI5:</strong> Scaling is like adding more shelves to a library or hiring more librarians, so the library can handle more books and visitors without getting overwhelmed.</p>
                </section>

                <section>
                    <h2>8. Development with VS Code</h2>
                    <p>Using VS Code can enhance your PostgreSQL development experience, especially when writing SQL queries and scripts. Here are some recommended tools and extensions:</p>
                    <ul>
                        <li><a href="https://marketplace.visualstudio.com/items?itemName=ckolkman.vscode-postgres" target="_blank" rel="noopener noreferrer">PostgreSQL for VS Code</a> - This extension allows you to connect to PostgreSQL instances, run queries, and explore schemas directly within VS Code.</li>
                        <li><a href="https://marketplace.visualstudio.com/items?itemName=dbaeumer.vscode-eslint" target="_blank" rel="noopener noreferrer">ESLint</a> - Helps maintain clean and consistent JavaScript code when working with PostgreSQL in Node.js applications.</li>
                        <li><a href="https://marketplace.visualstudio.com/items?itemName=esbenp.prettier-vscode" target="_blank" rel="noopener noreferrer">Prettier</a> - Ensures consistent formatting for your SQL, JavaScript, and other code.</li>
                        <li><a href="https://marketplace.visualstudio.com/items?itemName=ms-vscode-remote.remote-containers" target="_blank" rel="noopener noreferrer">Remote - Containers</a> - Allows you to develop inside a Docker container, ensuring consistency between your development and production environments.</li>
                        <li><a href="https://marketplace.visualstudio.com/items?itemName=formulahendry.auto-rename-tag" target="_blank" rel="noopener noreferrer">SQL Formatter</a> - Automatically formats your SQL queries for better readability and maintainability.</li>
                    </ul>
                    <p><strong>Recommendation:</strong> Leverage these tools to streamline your PostgreSQL development workflow, ensuring your SQL queries are efficient and well-organized.</p>
                </section>

                <footer style={{ marginTop: '20px', borderTop: '1px solid #ccc', paddingTop: '10px' }}>
                    <p>© 2024 PostgreSQL SaaS Guide. All rights reserved.</p>
                </footer>
            </div>
        </div>
    );
}
