import Hero from '../components/Hero';
import Liner from '../blocks/Liner';
import ServicesList from '../components/ServicesList';
import EasyToUseGuide from '../components/EasyToUseGuide';
import Providers from '../components/Providers';
import AreYouLost from '../components/AreYouLost';
import Team from '../components/Team';
import ContactUs from '../components/ContactUs';


export default function Home() {
    return (
        <>
            <Hero
                titleText={'Affordable & Powerful Cloud Instances by ICLabs'}
                subTitle={`Introducing a game-changing innovation in cloud computing and instance rentals that is set to disrupt the
                     industry. Our cutting-edge technology redefines what’s possible in the cloud, offering unparalleled performance,
                      scalability, and flexibility. Whether you’re a startup or an enterprise, our cloud solutions are designed to
                       empower your business with the tools you need to innovate faster and scale smarter.`}
                salesText={'Join milions of developers better'} />

            <Liner>
            ☞ Shipping, prototyping or just playing around must be EASY ☜
            </Liner>
            <EasyToUseGuide />
            <Liner>
            ☞ Pay less, like, waaay less, for same, or more.. Its this EASY ☜
            </Liner>
            <Providers />
            <AreYouLost />
            <Team />
        </>
    );
}