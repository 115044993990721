import mongodb from '../../assets/services/mongodb.png';
import { Link } from 'react-router-dom';

export default function MongoDbEdu() {
    return (
        <div>
            <Link to={'/education'} className='fixed border border-SCS text-SCS rounded-lg p-4 bottom-1/4 right-16 z-0 w-fit backButton'>❰ Back</Link>

            <div className='flex flex-col p-16 gap-16 w-2/3 mx-auto bg-SCS my-8 rounded-lg'>
                <div className='flex items-center mx-auto gap-4'>
                    <img src={mongodb} className='w-32' alt="MongoDB Logo" />
                    <h1 className='code'>MongoDB SaaS QuickGuide</h1>
                </div>

                <section>
                    <h2>1. Introduction to MongoDB</h2>
                    <p>MongoDB is a NoSQL database that stores data in flexible, JSON-like documents, making it ideal for applications that require fast, scalable access to large amounts of unstructured or semi-structured data.</p>
                    <p><strong>ELI5:</strong> Imagine a huge, organized notebook where each page (document) can hold different types of information, but you can still easily find what you're looking for.</p>
                </section>

                <section>
                    <h2>2. Getting Started with MongoDB</h2>
                    <p>After signing up for a MongoDB SaaS account, you’ll start by setting up your database. Here’s how to get started:</p>
                    <ul>
                        <li><strong>Create a Cluster:</strong> In the MongoDB dashboard, create a new cluster where your databases will be hosted.</li>
                        <li><strong>Access the Database:</strong> Use the connection string provided by your SaaS provider to connect to the database. You can use MongoDB Compass (a GUI tool) or connect via the MongoDB shell.</li>
                        <li><strong>Create a Database:</strong> After connecting, create a new database and collection (similar to a table in SQL) to start storing data.</li>
                    </ul>
                    <p><strong>Recommendation:</strong> Start by exploring MongoDB Compass to visualize your data and understand the structure of documents and collections.</p>
                </section>

                <section>
                    <h2>3. Basic MongoDB Queries</h2>
                    <p>MongoDB uses a powerful query language to interact with your data. Here are some common queries:</p>
                    <ul>
                        <li><strong>Find Documents:</strong> Use the <code>find()</code> method to retrieve documents from a collection.
                            <pre>{`db.collection('users').find({ age: { $gte: 18 } })`}</pre>
                            This query finds all users who are 18 years or older.
                        </li>
                        <li><strong>Insert Documents:</strong> Add new documents to a collection using <code>insertOne()</code> or <code>insertMany()</code>.
                            <pre>{`db.collection('users').insertOne({ name: 'John Doe', age: 30, email: 'john@example.com' })`}</pre>
                            This query inserts a new user into the users collection.
                        </li>
                        <li><strong>Update Documents:</strong> Modify existing documents with <code>updateOne()</code> or <code>updateMany()</code>.
                            <pre>{`db.collection('users').updateOne({ name: 'John Doe' }, { $set: { age: 31 } })`}</pre>
                            This query updates the age of John Doe to 31.
                        </li>
                        <li><strong>Delete Documents:</strong> Remove documents using <code>deleteOne()</code> or <code>deleteMany()</code>.
                            <pre>{`db.collection('users').deleteOne({ name: 'John Doe' })`}</pre>
                            This query deletes the user named John Doe from the users collection.
                        </li>
                    </ul>
                    <p><strong>ELI5:</strong> Queries are like asking your database questions or giving it commands, like "find all the people older than 18" or "add this new person to the list."</p>
                </section>

                <section>
                    <h2>4. Advanced Query Techniques</h2>
                    <p>As you grow more comfortable with MongoDB, you can start using more advanced query techniques:</p>
                    <ul>
                        <li><strong>Aggregation Framework:</strong> Perform complex data processing and transformations using the aggregation pipeline.
                            <pre>{`db.collection('orders').aggregate([
  { $match: { status: 'completed' } },
  { $group: { _id: '$customerId', total: { $sum: '$amount' } } },
  { $sort: { total: -1 } }
])`}</pre>
                            This query calculates the total amount spent by each customer on completed orders and sorts them in descending order.
                        </li>
                        <li><strong>Text Search:</strong> Use text indexes to perform searches on text fields.
                            <pre>{`db.collection('articles').find({ $text: { $search: 'MongoDB indexing' } })`}</pre>
                            This query searches for articles containing the phrase "MongoDB indexing".
                        </li>
                        <li><strong>Geospatial Queries:</strong> Work with location-based data using geospatial queries.
                            <pre>{`db.collection('places').find({
  location: {
    $near: {
      $geometry: { type: 'Point', coordinates: [ -73.9667, 40.78 ] },
      $maxDistance: 1000
    }
  }
})`}</pre>
                            This query finds places within 1000 meters of the specified coordinates.
                        </li>
                    </ul>
                    <p><strong>Recommendation:</strong> Experiment with these advanced queries to analyze your data in more complex ways, especially when dealing with large datasets.</p>
                </section>

                <section>
                    <h2>5. Indexing and Performance</h2>
                    <p>Indexing is crucial for optimizing query performance in MongoDB. Here’s how to manage indexes:</p>
                    <ul>
                        <li><strong>Create an Index:</strong> Use the <code>createIndex()</code> method to improve query performance.
                            <pre>{`db.collection('users').createIndex({ email: 1 })`}</pre>
                            This creates an index on the email field in ascending order, making lookups faster.
                        </li>
                        <li><strong>Compound Indexes:</strong> Combine multiple fields into a single index for more efficient querying.
                            <pre>{`db.collection('users').createIndex({ lastName: 1, firstName: 1 })`}</pre>
                            This creates a compound index on both lastName and firstName.
                        </li>
                        <li><strong>Unique Indexes:</strong> Enforce uniqueness on a field to prevent duplicate entries.
                            <pre>{`db.collection('users').createIndex({ username: 1 }, { unique: true })`}</pre>
                            This ensures that no two users can have the same username.
                        </li>
                        <li><strong>TTL Indexes:</strong> Automatically delete documents after a certain period.
                            <pre>{`db.collection('sessions').createIndex({ createdAt: 1 }, { expireAfterSeconds: 3600 })`}</pre>
                            This creates a TTL (Time To Live) index that automatically deletes session documents after one hour.
                        </li>
                    </ul>
                    <p><strong>ELI5:</strong> Indexes are like shortcuts in a library that help you find books faster. Without them, you’d have to look through every book one by one.</p>
                </section>

                <section>
                    <h2>6. Security Best Practices</h2>
                    <p>Securing your MongoDB instance is essential to protect your data. Here are some best practices:</p>
                    <ul>
                        <li><strong>Enable Authentication:</strong> Ensure that authentication is enabled to prevent unauthorized access.</li>
                        <li><strong>Use Role-Based Access Control (RBAC):</strong> Assign roles to users to control their access to specific resources and operations.</li>
                        <li><strong>Enable Encryption:</strong> Encrypt data at rest and in transit using MongoDB’s built-in encryption features.</li>
                        <li><strong>Regular Backups:</strong> Schedule regular backups of your MongoDB databases to prevent data loss in case of failure.</li>
                        <li><strong>Audit Logs:</strong> Enable audit logging to keep track of who is accessing and modifying your data.</li>
                    </ul>
                    <p><strong>Recommendation:</strong> Regularly review your security settings and update them as needed to stay ahead of potential threats.</p>
                </section>

                <section>
                    <h2>7. Scaling MongoDB</h2>
                    <p>As your data grows, you may need to scale your MongoDB deployment:</p>
                    <ul>
                        <li><strong>Vertical Scaling:</strong> Increase the resources (CPU, RAM) of your MongoDB server to handle more data and traffic.</li>
                        <li><strong>Horizontal Scaling:</strong> Use sharding to distribute data across multiple servers. This allows MongoDB to scale out horizontally, managing larger datasets more efficiently.</li>
                        <li><strong>Replication:</strong> Set up replica sets to ensure high availability and data redundancy.</li>
                        <li><strong>Cluster Management:</strong> Use MongoDB Atlas (if applicable) to manage scaling automatically, without the need for manual intervention.</li>
                    </ul>
                    <p><strong>ELI5:</strong> Scaling is like making sure your database can handle more and more data, like adding extra shelves in a library as your book collection grows.</p>
                </section>

                <section>
                    <h2>8. Development with VS Code</h2>
                    <p>Using VS Code can enhance your MongoDB development experience, especially when working with MongoDB queries and scripts. Here are some recommended tools and extensions:</p>
                    <ul>
                        <li><a href="https://marketplace.visualstudio.com/items?itemName=mongodb.mongodb-vscode" target="_blank" rel="noopener noreferrer">MongoDB for VS Code</a> - This extension allows you to connect to MongoDB instances, run queries, and interact with data directly within VS Code.</li>
                        <li><a href="https://marketplace.visualstudio.com/items?itemName=dbaeumer.vscode-eslint" target="_blank" rel="noopener noreferrer">ESLint</a> - Helps maintain clean and consistent JavaScript code when writing MongoDB queries and scripts.</li>
                        <li><a href="https://marketplace.visualstudio.com/items?itemName=esbenp.prettier-vscode" target="_blank" rel="noopener noreferrer">Prettier</a> - Ensures consistent formatting for your JavaScript/Node.js code.</li>
                        <li><a href="https://marketplace.visualstudio.com/items?itemName=ms-vscode-remote.remote-containers" target="_blank" rel="noopener noreferrer">Remote - Containers</a> - Allows you to develop inside a Docker container, ensuring consistency between your development and production environments.</li>
                        <li><a href="https://marketplace.visualstudio.com/items?itemName=formulahendry.auto-rename-tag" target="_blank" rel="noopener noreferrer">Auto Rename Tag</a> - Automatically renames paired HTML/XML tags, useful for editing HTML documents or MongoDB queries that use complex structures.</li>
                    </ul>
                    <p><strong>Recommendation:</strong> Leverage these tools to streamline your MongoDB development workflow and ensure your code is clean, efficient, and well-organized.</p>
                </section>

                <footer style={{ marginTop: '20px', borderTop: '1px solid #ccc', paddingTop: '10px' }}>
                    <p>© 2024 MongoDB SaaS Guide. All rights reserved.</p>
                </footer>
            </div>
        </div>
    );
}
