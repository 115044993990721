import wordpress from '../../assets/services/wordpress.png';
import { Link } from 'react-router-dom';

export default function WordPressEdu() {
    return (
        <div>
            <Link to={'/education'} className='fixed border border-SCS text-SCS rounded-lg p-4 bottom-1/4 right-16 z-0 w-fit backButton'>❰ Back</Link>

            <div className='flex flex-col p-16 gap-16 w-2/3 mx-auto bg-SCS my-8 rounded-lg'>
                <div className='flex items-center mx-auto gap-4'>
                    <img src={wordpress} className='w-32' alt="WordPress Logo" />
                    <h1 className='code'>WordPress SaaS QuickGuide</h1>
                </div>

                <section>
                    <h2>1. Introduction to WordPress</h2>
                    <p>WordPress is a popular content management system (CMS) that powers over 40% of websites on the internet. It allows users to create, manage, and publish content easily, with a wide range of themes and plugins available to extend its functionality.</p>
                    <p><strong>ELI5:</strong> WordPress is like a website-building toolkit that lets you create and manage a website without needing to know how to code.</p>
                </section>

                <section>
                    <h2>2. Getting Started with WordPress</h2>
                    <p>After signing up for a WordPress SaaS account, you'll start by setting up your website. Here’s how to get started:</p>
                    <ul>
                        <li><strong>Login:</strong> Access your WordPress dashboard using the credentials provided by your SaaS provider.</li>
                        <li><strong>Choose a Theme:</strong> Go to "Appearance"{' > '}"Themes" to select and activate a theme that fits your website's purpose and style.</li>
                        <li><strong>Install Plugins:</strong> Extend your website’s functionality by installing plugins. Go to "Plugins"{' > '}"Add New" to explore and install plugins.</li>
                        <li><strong>Create Pages:</strong> Start by creating essential pages like Home, About, and Contact. Go to "Pages"{' > '}"Add New" to create and publish pages.</li>
                    </ul>
                    <p><strong>Recommendation:</strong> Start with a simple theme and minimal plugins, then gradually add more features as you become comfortable with WordPress.</p>
                </section>

                <section>
                    <h2>3. Theme Management in WordPress</h2>
                    <p>Themes control the overall look and feel of your WordPress site. Here’s how to manage themes effectively:</p>
                    <ul>
                        <li><strong>Install Themes:</strong> Browse the theme repository or upload a custom theme. Go to "Appearance"{' > '}"Themes" and click "Add New" to explore and install themes.</li>
                        <li><strong>Customize Themes:</strong> Most themes offer customization options. Go to "Appearance"{' > '}"Customize" to adjust the theme settings such as colors, fonts, and layout.</li>
                        <li><strong>Child Themes:</strong> If you want to make extensive changes to a theme, consider creating a child theme to preserve your changes during theme updates.</li>
                        <li><strong>Responsive Design:</strong> Ensure that your theme is responsive, meaning it looks good on all devices, including desktops, tablets, and smartphones.</li>
                    </ul>
                    <p><strong>ELI5:</strong> Themes are like the clothes of your website. They change how your site looks, and you can switch them out or customize them to fit your style.</p>
                </section>

                <section>
                    <h2>4. Plugins and Extensions</h2>
                    <p>Plugins are tools that extend the functionality of your WordPress site. Here’s how to manage and use plugins:</p>
                    <ul>
                        <li><strong>Install Plugins:</strong> Go to "Plugins"{' > '}"Add New" to search for and install plugins that add features like contact forms, SEO tools, or social media integration.</li>
                        <li><strong>Essential Plugins:</strong> Some must-have plugins include:
                            <ul>
                                <li><strong>Yoast SEO:</strong> Helps optimize your site for search engines.</li>
                                <li><strong>Akismet:</strong> Protects your site from spam.</li>
                                <li><strong>WP Super Cache:</strong> Improves your site’s performance by caching pages.</li>
                                <li><strong>Elementor:</strong> A powerful page builder that allows you to design custom pages easily.</li>
                            </ul>
                        </li>
                        <li><strong>Plugin Updates:</strong> Keep your plugins up to date to ensure compatibility and security. Go to "Plugins"{' > '}"Installed Plugins" to manage updates.</li>
                        <li><strong>Deactivate/Remove Plugins:</strong> If a plugin is no longer needed, deactivate it and consider removing it to keep your site clean and fast.</li>
                    </ul>
                    <p><strong>Recommendation:</strong> Only install plugins that you truly need, as too many plugins can slow down your site and create security risks.</p>
                </section>

                <section>
                    <h2>5. Content Creation and Management</h2>
                    <p>Content is the heart of your WordPress site. Here’s how to create and manage it effectively:</p>
                    <ul>
                        <li><strong>Create Posts:</strong> Go to "Posts"{' > '}"Add New" to start writing blog posts. Use categories and tags to organize your content.</li>
                        <li><strong>Manage Media:</strong> Upload and manage images, videos, and other media through the "Media" library. Optimize images for faster loading times.</li>
                        <li><strong>Use Blocks:</strong> WordPress uses a block-based editor called Gutenberg. Each piece of content (paragraph, image, etc.) is a block that you can move and customize.</li>
                        <li><strong>Page Templates:</strong> Use pre-built page templates or create your own to maintain a consistent look across your site.</li>
                    </ul>
                    <p><strong>ELI5:</strong> Think of your WordPress site as a scrapbook. Pages are like the different sections, and blocks are the pieces you use to fill those sections with content.</p>
                </section>

                <section>
                    <h2>6. Security Best Practices</h2>
                    <p>Keeping your WordPress site secure is crucial. Here are some best practices:</p>
                    <ul>
                        <li><strong>Use Strong Passwords:</strong> Ensure all users have strong, unique passwords. Consider using a password manager.</li>
                        <li><strong>Enable Two-Factor Authentication:</strong> Add an extra layer of security by enabling two-factor authentication (2FA) for user logins.</li>
                        <li><strong>Keep WordPress Updated:</strong> Regularly update WordPress, themes, and plugins to protect against vulnerabilities. Updates can be managed in the "Dashboard"{' > '}"Updates" section.</li>
                        <li><strong>Backup Regularly:</strong> Use a plugin like UpdraftPlus to schedule regular backups of your site. Store backups in a secure location.</li>
                        <li><strong>Security Plugins:</strong> Consider installing a security plugin like Wordfence or Sucuri to monitor and protect your site from threats.</li>
                    </ul>
                    <p><strong>Recommendation:</strong> Regularly review your site’s security settings and perform audits to ensure there are no vulnerabilities.</p>
                </section>

                <section>
                    <h2>7. SEO and Performance Optimization</h2>
                    <p>Optimizing your WordPress site for search engines and performance is key to attracting visitors and ensuring a smooth user experience:</p>
                    <ul>
                        <li><strong>SEO Plugins:</strong> Use plugins like Yoast SEO or Rank Math to optimize your site’s content for search engines. Focus on keyword optimization, meta descriptions, and readability.</li>
                        <li><strong>Site Speed:</strong> Improve your site’s loading time by using a caching plugin (e.g., WP Super Cache), optimizing images, and choosing a fast and reliable hosting provider.</li>
                        <li><strong>Mobile Optimization:</strong> Ensure your site is mobile-friendly by using a responsive theme and testing your site on different devices.</li>
                        <li><strong>Optimize Images:</strong> Use tools like Smush or ShortPixel to compress images without losing quality, reducing load times.</li>
                        <li><strong>Minimize Plugins:</strong> Deactivate and remove unnecessary plugins to reduce overhead and speed up your site.</li>
                    </ul>
                    <p><strong>ELI5:</strong> SEO is like telling search engines (like Google) what your site is about, so they can show it to the right people. Performance optimization is like making sure your site is fast, so visitors don’t leave because it’s too slow.</p>
                </section>

                <section>
                    <h2>8. Scaling and Hosting Considerations</h2>
                    <p>As your site grows, you may need to scale your WordPress site and consider hosting options:</p>
                    <ul>
                        <li><strong>Choose the Right Hosting:</strong> Managed WordPress hosting providers (e.g., WP Engine, SiteGround) offer optimized environments that include caching, backups, and security features.</li>
                        <li><strong>Content Delivery Network (CDN):</strong> Use a CDN like Cloudflare to distribute your site’s content globally, improving load times for international visitors.</li>
                        <li><strong>Database Optimization:</strong> Regularly clean and optimize your WordPress database using plugins like WP-Optimize to improve performance.</li>
                        <li><strong>Scaling with Traffic:</strong> Monitor your site’s traffic and upgrade your hosting plan as needed to handle increased visitors without downtime.</li>
                    </ul>
                    <p><strong>Recommendation:</strong> Work with your hosting provider to understand your site’s needs and ensure you have the right resources to support growth.</p>
                </section>

                <section>
                    <h2>9. Development with VS Code</h2>
                    <p>Using VS Code can greatly enhance your WordPress development experience, especially for theme and plugin development. Here are some recommended extensions and tools:</p>
                    <ul>
                        <li><a href="https://marketplace.visualstudio.com/items?itemName=ms-azuretools.vscode-docker" target="_blank" rel="noopener noreferrer">Docker</a> - Useful if you are running WordPress in a containerized environment. This extension integrates Docker into VS Code, making it easier to manage containers.</li>
                        <li><a href="https://marketplace.visualstudio.com/items?itemName=ryannaddy.laravel-artisan" target="_blank" rel="noopener noreferrer">PHP Intelephense</a> - A powerful PHP language server that provides autocomplete, go to definition, and other PHP-specific features.</li>
                        <li><a href="https://marketplace.visualstudio.com/items?itemName=esbenp.prettier-vscode" target="_blank" rel="noopener noreferrer">Prettier</a> - Ensures consistent code formatting across your PHP, JavaScript, and CSS files.</li>
                        <li><a href="https://marketplace.visualstudio.com/items?itemName=dbaeumer.vscode-eslint" target="_blank" rel="noopener noreferrer">ESLint</a> - Integrates ESLint into VS Code, which helps maintain clean JavaScript code in your WordPress themes and plugins.</li>
                        <li><a href="https://marketplace.visualstudio.com/items?itemName=bradlc.vscode-tailwindcss" target="_blank" rel="noopener noreferrer">Tailwind CSS IntelliSense</a> - If you’re using Tailwind CSS in your WordPress themes, this extension provides autocomplete and syntax highlighting for Tailwind classes.</li>
                    </ul>
                    <p><strong>Recommendation:</strong> Set up a local development environment using Docker and VS Code to test changes before deploying them to your live site.</p>
                </section>

                <footer style={{ marginTop: '20px', borderTop: '1px solid #ccc', paddingTop: '10px' }}>
                    <p>© 2024 WordPress SaaS Guide. All rights reserved.</p>
                </footer>
            </div>
        </div>
    );
}
