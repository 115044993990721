
import { useEffect } from 'react';
import GuideTextSection from '../blocks/GuideTextSection';
import GuideVisualSection from '../blocks/GuideVisualSection';
import { useSelector } from 'react-redux';

type Service = {
    id: number;
    saas: string;
    name: string;
    vcpu: number;
    ram: number;
    storage: number;
    price: string;
    currency: string;
    active: boolean;
    numberofinstances: number;
};
type SelectorAllServices ={
    selectorAllServices: Service[]
}

export default function EasyToUseGuide() {
    const selectorAllServices = useSelector((state: any) => state.services.services);
    useEffect(() => {
        console.log(selectorAllServices);
    }
        , [selectorAllServices]);
        /*
            {
        id:1
        saas:"Node-RED"
        name:"Node-RED ESSENTIAL"
        vcpu:1
        ram:1
        storage:5
        price:"5.00"
        currency:"EUR"
        active:true
        numberofinstances:1
        }
         */
    const instanceOptions = {

        MongoDB: [{
            name: 'MongoDB',
            type: 'tiny',
            price: '5',
            vcpu: '1',
            ram: '2',
            storage: '8'
        },
        {
            name: 'MongoDB',
            type: 'basic',
            price: '15',
            vcpu: '2',
            ram: '8',
            storage: '16'

        },
        {
            name: 'MongoDB',
            type: 'intermediate',
            price: '30',
            vcpu: '4',
            ram: '16',
            storage: '32'
        },
        {
            name: 'MongoDB',
            type: 'metal',
            price: '60',
            vcpu: '8',
            ram: '32',
            storage: '128'
        }],
        WooCommerce: [{
            name: 'WooCommerce',
            type: 'tiny',
            price: '5',
            vcpu: '1',
            ram: '2',
            storage: '4'
        },
        {
            name: 'WooCommerce',
            type: 'basic',
            price: '15',
            vcpu: '1',
            ram: '4',
            storage: '8'

        },
        {
            name: 'WooCommerce',
            type: 'intermediate',
            price: '30',
            vcpu: '2',
            ram: '8',
            storage: '16'
        },
        {
            name: 'WooCommerce',
            type: 'metal',
            price: '60',
            vcpu: '2',
            ram: '16',
            storage: '128'
        }],
        WordPress: [{
            name: 'WordPress',
            type: 'tiny',
            price: '5',
            vcpu: '1',
            ram: '2',
            storage: '4'
        },
        {
            name: 'WordPress',
            type: 'basic',
            price: '15',
            vcpu: '2',
            ram: '4',
            storage: '16'

        },
        {
            name: 'WordPress',
            type: 'intermediate',
            price: '30',
            vcpu: '2',
            ram: '8',
            storage: '32'
        },
        {
            name: 'WordPress',
            type: 'metal',
            price: '60',
            vcpu: '4',
            ram: '32',
            storage: '128'
        }],
        PostGre: [{
            name: 'PostGre',
            type: 'tiny',
            price: '5',
            vcpu: '1',
            ram: '2',
            storage: '8'
        },
        {
            name: 'PostGre',
            type: 'basic',
            price: '15',
            vcpu: '2',
            ram: '8',
            storage: '16'

        },
        {
            name: 'PostGre',
            type: 'intermediate',
            price: '30',
            vcpu: '4',
            ram: '16',
            storage: '32'
        },
        {
            name: 'PostGre',
            type: 'metal',
            price: '60',
            vcpu: '8',
            ram: '32',
            storage: '128'
        }],
        Grafana: [{
            name: 'Grafana',

            type: 'tiny',
            price: '5',
            vcpu: '1',
            ram: '2',
            storage: '8'
        },
        {
            name: 'Grafana',
            type: 'basic',
            price: '15',
            vcpu: '2',
            ram: '8',
            storage: '8'

        },
        {
            name: 'Grafana',
            type: 'intermediate',
            price: '30',
            vcpu: '4',
            ram: '16',
            storage: '32'
        },
        {
            name: 'Grafana',
            type: 'metal',
            price: '60',
            vcpu: '8',
            ram: '32',
            storage: '128'
        }],
        NodeRed: [{
            name: 'NodeRed',
            type: 'tiny',
            price: '5',
            vcpu: '1',
            ram: '2',
            storage: '8'
        },
        {
            name: 'NodeRed',
            type: 'basic',
            price: '15',
            vcpu: '1',
            ram: '16',
            storage: '16'

        },
        {
            name: 'NodeRed',
            type: 'intermediate',
            price: '30',
            vcpu: '2',
            ram: '64',
            storage: '32'
        },
        {
            name: 'NodeRed',
            type: 'metal',
            price: '60',
            vcpu: '4',
            ram: '128',
            storage: '64'
        }],
        Ubuntu: [{
            name: 'Ubuntu',
            type: 'tiny',
            price: '5',
            vcpu: '1',
            ram: '4',
            storage: '16'
        },
        {
            name: 'Ubuntu',
            type: 'basic',
            price: '15',
            vcpu: '2',
            ram: '8',
            storage: '64'

        },
        {
            name: 'Ubuntu',
            type: 'intermediate',
            price: '30',
            vcpu: '8',
            ram: '32',
            storage: '128'
        },
        {
            name: 'Ubuntu',
            type: 'metal',
            price: '60',
            vcpu: '16',
            ram: '64',
            storage: '512'
        }],
    }

    return (
        <div className='bg-SC w-full py-8 md:py-36  '>
            <div className="flex flex-col lg:flex-row w-full justify-center">
                <GuideTextSection selectorAllServices={selectorAllServices} />
                <GuideVisualSection selectorAllServices={selectorAllServices} />
            </div>
        </div>
    );
}