import React, { useState } from 'react';
import world from '../assets/world.svg';



export default function Providers() {


    return (
        <div className='bg-SCS flex flex-col py-16 '>
            <div className='flex flex-col'>

                <div className='flex flex-col mx-auto pt-16 pb-8 px-4 md:px-0'>
                    <p className="text-ACS pl-2">say yes to honesty</p>
                    <h1 className='code'>We Support Smaller Datacenters Worldwide</h1>
                </div>
                <div className='flex px-4 md:px-0 md:w-1/2 mx-auto'>
                    <h4 className='text-TC'>We take pride in partnering with top-tier industry leaders to deliver outstanding cloud services. Our collaborations are carefully selected to ensure excellence at every level. Our partners are independent, highly reliable data centers. This approach guarantees the highest quality and security for your business needs.
                    </h4>
                </div>
            </div>
            {/*Mapa swiatu*/}
            <div className='flex justify-center items-center w-full world mt-36'>
                <img src={world} alt='World' className='w-2/3 worldMap' />
                <span className='absolute w-32 h-fit bg-SC -top-24 lg:top-0 left-2 lg:left-1/3 flex flex-col p-4 gap-4 rounded-lg' >
                <p>🖹 USA zone</p>
                <p>60 Providers</p>  
                <span className='hidden lg:block absolute w-2 h-52 left-0 bg-SC rounded-b-md '/>
                </span>
                <span className='absolute w-32 h-fit bg-SC -top-24 lg:top-0  right-2 lg:right-1/3 flex flex-col p-4 gap-4 rounded-lg' >
                <p>🖹 Asia zone</p>
                <p>17 Providers</p>  
                <span className='hidden lg:block absolute w-2 h-56 right-0 bg-SC  rounded-b-md'/>
                </span>
                <span className='absolute w-32 h-fit bg-SC -top-24 lg:top-0  flex flex-col p-4 gap-4 rounded-lg' >
                <p>🖹 EU zone</p>
                <p>31 Providers</p>  
                <span className='hidden lg:block absolute w-2 h-48 right-0 bg-SC rounded-b-md'/>
                </span>
            </div>
        </div>
    );
}
